import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const Auth = ()=> {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  return (
    <>
      <button onClick={() => loginWithRedirect()}> Login </button>
    </>
  )
}
