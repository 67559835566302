import React from 'react';
import logo from './logo.svg';
import { Auth } from './features/auth/Auth';
import './App.css';

function App() {
  return (
      <Auth />
  );
}

export default App;
